









































































import { Component, Vue } from 'vue-property-decorator';
import AuthConnector from '@/connector/Auth.vue';

@Component({
  components: {
    AuthConnector,
  },
})
export default class Login extends Vue {
  public username: string = '';

  public password: string = '';
}
